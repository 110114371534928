<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="addEvent()"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    label-class="h5"
                    label="Select Organisation"
                  >
                    <v-select
                      id="selectStartup"
                      v-model="event.organisation_id"
                      :get-option-label="option => option.users_organizationtable.title"
                      :options="userOrgs"
                      :reduce="org => org.organization_id"
                      placeholder="Choose one from the list"
                    />
                    <hr>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-overlay
                :show="!event.organisation_id"
                rounded="sm"
              >
                <template #overlay>
                  <b-card
                    bg-variant="primary"
                    text-variant="light"
                  >
                    Please Select Organisation
                  </b-card>
                </template>
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex mb-2"
                  >
                    <h4
                      class="mb-0 mr-1"
                      style="padding-top:2px"
                    >
                      <strong>Open To: </strong>
                    </h4>
                    <b-form-checkbox
                      v-b-tooltip="event.public ? 'Disable Public' : 'Make Public'"
                      :checked="event.public"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="updateStatus(!event.public)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="d-flex align-items-center">
                      <b-badge
                        :variant="event.public?'light-primary':'light-danger'"
                        style="text-transform: capitalize;font-size:16px;"
                        class="mr-1"
                      >
                        <strong>{{ event.public?"Public - Accessible to the entire Starworks network.":"Inhouse -  Accessible exclusively within your Incubator network." }}</strong>
                      </b-badge>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Job Title"
                      label-for="jobTitle"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Job Title"
                        rules="required"
                      >
                        <b-form-input
                          id="jobTitle"
                          v-model="event.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Job Title"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Job Type"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                        label="Job Type"
                        label-for="jobType"
                      >
                        <v-select
                          id="jobType"
                          v-model="event.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Full-Time', 'Part-Time', 'Internship', 'Live-Project', 'Other']"
                          label="Job Type"
                          placeholder="Job Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group
                      v-if="event.type === 'Other'"
                      label="Kindly Specify Details"
                      label-for="otherDetails"
                    >
                      <b-form-input
                        id="otherDetails"
                        v-model="event.otherDetails"
                        placeholder="Please specify the job type"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label="Job Description (Mention Responsibilities, Location, Experience etc.)"
                      label-for="jobDescription"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Job Description"
                        rules="required"
                      >
                        <b-form-input
                          id="jobDescription"
                          v-model="event.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Job Description"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Work Mode"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Work Mode"
                        label-for="jobMode"
                      >
                        <v-select
                          id="jobMode"
                          v-model="event.deliveryMode"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Onsite', 'Remote', 'Hybrid']"
                          label="Work Mode"
                          placeholder="Select work Mode"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="No. of Vacancies"
                      label-for="maxCapacity"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="No. of Vacancies"
                        rules="required"
                      >
                        <b-form-input
                          id="maxCapacity"
                          v-model="event.capacity"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter max capacity"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Upload Job Description"
                      label-for="jd"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Upload Job Description"
                      >
                        <b-form-file
                          id="jd"
                          v-model="event.file"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Upload Job Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col :md="event.type === 'Internship' || event.type === 'Live-Project' ? '3' : '6'">
                    <b-form-group
                      class="mb-2"
                      label="Expected Date of Joining"
                      label-for="expectedJoinDate"
                    >
                      <flat-pickr
                        id="expectedJoinDate"
                        v-model="event.beginTime"
                        class="form-control"
                        placeholder="Select Join Date"
                        :config="{
                          minDate: new Date()
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="event.type === 'Internship' || event.type === 'Live-Project'"
                    md="3"
                  >
                    <b-form-group
                      class="mb-2"
                      label="Expected Duration"
                      label-for="internDuration"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Expected Duration"
                        rules="required"
                      >
                        <b-form-input
                          id="internDuration"
                          v-model="event.duration"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Expected Duration"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <h5 class="mb-0.5">
                      Benefits and Remuneration
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label=""
                        label-for="remuneration"
                      >
                        <v-select
                          id="remuneration"
                          v-model="event.prize"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Letter of Internship', 'Stipend', 'Salary', 'PF', 'Health Insurance', 'Relocation Alllowances', 'Rent Allowances', 'Travel Allowances', 'Other Perks, Offers and Benefits']"
                          multiple
                          label=""
                          placeholder="Select Benefits and Remuneration"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-col md="12">
                      <div
                        v-for="(item, i) in event.prize"
                        :key="i"
                      >
                        <b-row v-if="item==='Certificate of completion'">
                          <b-col
                            v-if="event.certificate"
                            md="6"
                          >
                            <b-form-group
                              label="Certificate Details"
                              label-for="details"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name=" Certificate Details"
                              >
                                <b-form-input
                                  id="details"
                                  v-model="event.certificateCapacity"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Certificate Details"
                                  type="text"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item==='Salary'">
                          <b-col
                            v-if="event.certificate"
                            md="12"
                          >
                            <b-form-group
                              label="Salary Details (Mention if the remuneration is per-month(pm) or per-annum(PA), Also mention CTC and Cash-in-hand for best fit applicants.)"
                              label-for="details"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name=" Salary Details"
                              >
                                <b-form-input
                                  id="details"
                                  v-model="event.Salary"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Enter Salary Details"
                                  type="text"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item==='Stipend'">
                          <b-col md="12">
                            <b-form-group
                              label="Stipend Details (Mention if the remuneration is per-month(pm) or per-annum(PA), Also mention CTC and Cash-in-hand for best fit applicants.)"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Total Prize Pool"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="event.cashPrize"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Stipend Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item==='Relocation Alllowances'">
                          <b-col md="12">
                            <b-form-group
                              label="Relocation Alllowances Details (Mention if the remuneration is per-month(pm) or per-annum(PA))"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Total Prize Pool"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="event.rladetails"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item==='Rent Allowances'">
                          <b-col md="12">
                            <b-form-group
                              label="Rent Allowances Details (Mention if the remuneration is per-month(pm) or per-annum(PA))"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Total Prize Pool"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="event.radetails"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item==='Travel Allowances'">
                          <b-col md="12">
                            <b-form-group
                              label="Travel Allowances Details (Mention if the remuneration is per-month(pm) or per-annum(PA))"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Total Prize Pool"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="event.tadetails"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row v-if="item === 'Health Insurance'">
                          <b-col md="12">
                            <b-form-group
                              label="Health Insurance Details"
                              label-for="healthInsuranceDetails"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Health Insurance Details"
                              >
                                <b-form-input
                                  id="healthInsuranceDetails"
                                  v-model="event.healthInsuranceDetails"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Enter Health Insurance Details"
                                  type="text"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row v-if="item === 'Other Perks, Offers and Benefits'">
                          <b-col md="12">
                            <b-form-group
                              label="Specify Perks, Offers and Benefits Details"
                              label-for="competitionPerks"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Specify Perks, Offers and Benefits Details"
                              >
                                <b-form-input
                                  id="competitionPerks"
                                  v-model="event.prizePerks"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Enter Perks, Offers and Benefits Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-col>
                  <!-- criteria start -->
                  <!-- criteria end -->
                  <!-- limit component -->
                  <!-- limit component -->
                </b-row>
              </b-overlay>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
    <!-- modal starts -->
    <b-modal
      id="eventModal"
      :ok-title="`${(event.lifeCycleStage && event.industry && event.customerFocus && event.offeringsType && event.impactFocus && event.lifeCycleStage.length === 0 && event.industry.length === 0 && event.customerFocus.length === 0 && event.offeringsType.length === 0 && event.impactFocus.length === 0)?'Skip':'Save'}`"
      no-close-on-backdrop
      size="lg"
      title="Additional Details"
      @ok="handlenewsave"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Ideal Startup Lifecycle Stage"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Ideal Startup Lifecycle Stage"
              label-for="lifeCycleStage"
            >
              <v-select
                id="lifeCycleStage"
                v-model="event.lifeCycleStage"
                :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                multiple
                placeholder="Select one or multiple options"
                style="z-index: 1000;"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Participant Industry/Sector"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Participant Industry/Sector"
              label-for="industry"
            >

              <v-select
                id="industry"
                v-model="event.industry"
                :options="industries"
                multiple
                placeholder="Select one or multiple options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <h6><strong>Focus</strong></h6>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Customer Focus"
            label-for="customerFocus"
          >
            <v-select
              id="customerFocus"
              v-model="event.customerFocus"
              :options="['B2C', 'D2C', 'B2B', 'B2G', 'B2B2C', 'B2B2B', 'B2C2B', 'B2C2C', 'B2B2G', 'B2G2B', 'B2G2C', 'B2C2G']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Offerings Type"
            label-for="offeringsType"
          >
            <v-select
              id="offeringsType"
              v-model="event.offeringsType"
              :options="['Product', 'Service', 'Hybrid']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Impact Focus"
            label-for="impactFocus"
          >
            <v-select
              id="impactFocus"
              v-model="event.impactFocus"
              :options="['Profit', 'Social', 'Environmental']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <form-builder
        v-model="applicationForm"
      />
    </b-modal>
    <b-modal
      id="submitModal"
      ok-title="Yes, Submit"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      centered="true"
      size="sm"
      title="Confirm Submit"
      @ok="addEvent()"
      @cancel="$bvModal.show('eventModal')"
    >
      <h6>Adding additional details helps you get the right applicants and participants. Are you sure you do not want to add details?</h6>
    </b-modal>
    <!-- modal ends -->
  </b-row>
</template>

<script>
import {
  // BButton,
  BCard,
  // BCardText,
  BCol,
  BBadge,
  BFormCheckbox,
  // BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  // BTab,
  // BTabs,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    flatPickr,
    BFormFile,
    BBadge,
    // AppTimeline,
    // AppTimelineItem,
    // BTabs,
    // BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // BButton,
    BFormCheckbox,
    // BFormCheckboxGroup,
    // BCardText,

    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      event: {
        organisation_id: null,
        compType: true,
        criteriaList: [],
        eventId: null,
        title: null,
        type: null,
        otherDetails: null,
        JobMode: null,
        duration: null,
        opportunity: null,
        prize: [],
        prizeDetail: {},
        teamSize: [1, null],
        noTeamLimit: false,
        investmentNumber: null,
        certificateCapacity: null,
        investment: null,
        Salary: null,
        cashPrize: null,
        cashPrizeNumber: null,
        certificate: true,
        prizePerks: null,
        healthInsuranceDetails: null,
        radetails: null,
        rladetails: null,
        tadetails: null,
        fundraisingLimit: null,
        awardtitle: null,
        awarddescription: null,
        granttitle: null,
        grantdescription: null,
        recognitiontitle: null,
        recognitiondescription: null,
        partnertitle: null,
        partnerdescription: null,
        participation: null,
        description: null,
        deliveryMode: null,
        capacity: null,
        beginTime: null,
        endTime: null,
        lifeCycleStage: [],
        industry: [],
        customerFocus: [],
        offeringsType: [],
        impactFocus: [],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inhouseFunds: null,
        file: null,
        public: true,
        // inhouseFundsSize: ['', ''],
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      is_internal: true,
      phasesTab: null,
      required,
      email,
      industries: ['Agnostic',
        'Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      // console.log(arr)
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj) // Remove nulls
      this.handleselection(newarr)
      return newarr
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    handleminparticipants() {
      if (this.event.teamSize[0] < 1) {
        this.event.teamSize[0] = 1
      }
    },
    handleselection(getarr) {
      if (getarr.length === 1) {
        this.event.organisation_id = getarr[0].organization_id
      }
    },
    updateStatus(newstatus) {
      this.event.public = newstatus
    },
    updateCertificate(newstatus) {
      this.event.certificate = newstatus
    },
    updateCompType(newstatus) {
      this.event.compType = newstatus
    },
    handlenewsave() {
      if (this.event.lifeCycleStage && this.event.industry && this.event.customerFocus && this.event.offeringsType && this.event.impactFocus && this.event.lifeCycleStage.length === 0 && this.event.industry.length === 0 && this.event.customerFocus.length === 0 && this.event.offeringsType.length === 0 && this.event.impactFocus.length === 0) {
        this.$bvModal.show('submitModal')
      } else {
        this.addEvent()
      }
    },
    formatPhases(phases, eventId) {
      phases.forEach(phase => {
        phase.operations_type = 'Phase'
        phase.event_id = eventId
        phase.events_objectivestables = {
          data: phase.objectives,
        }
        delete phase.objectives
        phase.subphases.forEach(subphase => {
          subphase.operations_type = 'SubPhase'
          subphase.event_id = eventId
          const activities = []
          subphase.days.forEach((day, dayIndex) => {
            day.activities.forEach(activity => {
              activity.day = dayIndex + 1
              activity.event_id = eventId
              activity.events_objectivestables = {
                data: activity.objectives,
              }
              delete activity.objectives
              activities.push(activity)
            })
            subphase.events_operationstables = {
              data: activities,
            }
            delete subphase.days
          })
        })
        phase.events_operationstables = {
          data: phase.subphases,
        }
        delete phase.subphases
      })
      return phases
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addEvent()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },
    async addEvent() {
      this.mutationLoading = true
      const temp = []
      this.event.prize.map(pdata => {
        switch (pdata) {
          case 'Letter of Internship': temp.push({
            deliverable: 'Letter of Internship',
            details: this.event.certificateCapacity,
          })
            break
          case 'Stipend': temp.push({
            deliverable: 'Stipend',
            details: this.event.cashPrize,
          })
            break
          case 'Salary': temp.push({
            deliverable: 'Salary',
            details: this.event.Salary,
          })
            break
          case 'Relocation Alllowances': temp.push({
            deliverable: 'Relocation Alllowances',
            details: this.event.rladetails,
          })
            break
          case 'Rent Allowances': temp.push({
            deliverable: 'Rent Allowances',
            details: this.event.radetails,
          })
            break
          case 'Travel Allowances': temp.push({
            deliverable: 'Travel Allowances',
            details: this.event.tadetails,
          })
            break
          case 'Health Insurance':
            temp.push({
              deliverable: 'Health Insurance',
              details: this.event.healthInsuranceDetails,
            })
            break
          case 'Other Perks, Offers and Benefits': temp.push({
            deliverable: 'other',
            details: this.event.prizePerks,
          })
            break
          default: return 0
        }
        return 0
      })
      // console.log(temp)
      let fileLink = null
      if (this.event.file) {
        const formData = new FormData()
        formData.append('file', this.event.file)
        await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        }).then(response => response.json())
          .then(data => {
            fileLink = data.file_key
            return data
          })
      }
      const eventType = this.event.type === 'Other' ? this.event.otherDetails : this.event.type
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!
          ) {
          insert_programs_basicinfo_one(object: {
          status: "draft",
          is_job: true,
          type: "${eventType}",
          is_public: "${this.event.public}",
          title: "${this.event.title}",
          delivery_mode: "${this.event.deliveryMode}",
          begin_date: "${this.event.beginTime}",
          description: "${this.event.description}",
          organization_id_id: "${this.event.organisation_id}",
          capacity: "${this.event.capacity}",
          duration: "${this.event.duration}",
          image: "${fileLink}",
          programs_deliverablelisttables: {data: $deliverableInput}})
          {
            id
          }
        }`,
        variables: {
          deliverableInput: temp,
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_basicinfo_one.id ? 'Added new job listing successfully.' : 'Failed to create job listing',
              icon: insert_programs_basicinfo_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_basicinfo_one.id ? 'info' : 'danger',
            },
          })
          if (insert_programs_basicinfo_one.id) {
            // Program Created, Create Phases
            // this.addPhases(insert_programs_basicinfo_one.id)
            window.location.href = '/startup-portal/job-listing'
          } else {
            // Error Occurred, stop loading
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    addPhases(eventId) {
      this.$apollo.mutate({
        mutation: gql` mutation($objects: [events_operationstable_insert_input!]!) {
        insert_events_operationstable(objects: $objects) {
          affected_rows
        }
      }`,
        variables: {
          objects: this.formatPhases(this.event.phases, eventId),
        },
        update: () => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: insert_events_operationstable.affected_rows ? 'Phases created successfully' : 'Failed to create phases',
          //     icon: insert_events_operationstable.affected_rows ? 'CheckIcon' : 'XIcon',
          //     variant: insert_events_operationstable.affected_rows ? 'success' : 'danger',
          //   },
          // })
          setTimeout(() => {
            this.mutationLoading = false
            window.location.href = '/incubator-portal/events'
          }, 2000)
        },
      })
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseStructure() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseStructure.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseDetails.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              if (this.event.phases.length > 1) {
                this.$bvToast.toast('Please fill details for all phases!', {
                  title: 'Alert',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: true,
                  solid: true,
                })
              }
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
